/*
Copyright 2021, Staffbase GmbH and contributors.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import * as Luxon from 'luxon';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {
  Content,
  CurrentDay,
  Description,
  DescriptionSub,
  FloatingButton,
  FloatingButtonCount,
  FloatingButtons,
  Header,
  InfoSection,
  InfoSectionDesktopOnly,
  InfoTable,
  LastUpdateLabel,
  ShiftDetailsContainer,
  SubHeader,
} from './shift-detail.style';

import {ThemeProvider} from 'styled-components';
import {durationToString, isLastWeekOrEarlier} from '../../helpers/date-utils';
import {getTheme} from '../../helpers/theme-utils';
import {useToday} from '../../hooks/calendar';
import {ShiftDetails} from '../../types/shift';
import {MachinesModal} from '../machines-modal';
import {PersonnelModal} from '../personnel-modal';

interface DetailProps {
  readonly shiftDetails: ShiftDetails;
  readonly showDate?: boolean;
}

/**
 * Title and content are shown in a table styled way
 *
 * @param props
 */
const InfoTableComponent: React.FC<{
  tag?: string;
  description: string;
  content: string | string[];
  description_sub?: string;
}> = ({tag, description, description_sub, content}) => (
  <InfoTable>
    <Description>
      {description}
      {description_sub && <DescriptionSub>{description_sub}</DescriptionSub>}
    </Description>
    <Content data-tag={tag}>
      {Array.isArray(content) ? content.map((c, i) => <div key={i}>{c}</div>) : content}
    </Content>
  </InfoTable>
);

/**
 * Component to show all informations of a shift
 *
 * @param CardProps
 */
export const ShiftDetail: React.FC<DetailProps> = ({shiftDetails, showDate = false}) => {
  const {
    start,
    end,
    address,
    kolonne,
    contact,
    contact_phone,
    username,
    comment,
    machine,
    workplace,
    subproject,
    accommodation,
    meeting_point,
    last_update,
    machines,
    personnel,
    status,
  } = shiftDetails;
  const today = useToday();
  const startDate = Luxon.DateTime.fromISO(start);
  const endDate = Luxon.DateTime.fromISO(end);
  const lastUpdateDate = Luxon.DateTime.fromISO(last_update);

  const isToday = startDate.hasSame(today, 'day');
  const shiftInterval = Luxon.Interval.fromDateTimes(startDate, endDate);
  const {t} = useTranslation();
  const [showMachinesModal, setShowMachinesModal] = React.useState(false);
  const [showPersonnelModal, setShowPersonnelModal] = React.useState(false);

  return (
    <ThemeProvider theme={getTheme(isLastWeekOrEarlier(startDate))}>
      <ShiftDetailsContainer>
        <MachinesModal
          isOpen={showMachinesModal}
          onClose={() => setShowMachinesModal(false)}
          machines={machines}
        />
        <PersonnelModal
          isOpen={showPersonnelModal}
          onClose={() => setShowPersonnelModal(false)}
          personnel={personnel}
        />
        <FloatingButtons>
          <FloatingButton onClick={() => setShowPersonnelModal(true)}>
            {t('related_personnel')}
            <FloatingButtonCount>{personnel?.length}</FloatingButtonCount>
          </FloatingButton>
          <FloatingButton onClick={() => setShowMachinesModal(true)}>
            {t('related_machines')}
            <FloatingButtonCount>{machines?.length}</FloatingButtonCount>
          </FloatingButton>
        </FloatingButtons>
        {showDate && (
          <Header>
            {isToday && <CurrentDay>{t('today')}</CurrentDay>}
            {startDate.toLocaleString({weekday: 'long', month: 'long', day: '2-digit'})}
            {startDate.startOf('day') < endDate.startOf('day') &&
              ' - ' + endDate.toLocaleString({weekday: 'long', month: 'long', day: '2-digit'})}
          </Header>
        )}
        <InfoSection>
          <LastUpdateLabel>
            {t('last_update') + ': ' + lastUpdateDate.toFormat('dd.MM.yy HH:mm') + ' Uhr'}
          </LastUpdateLabel>
        </InfoSection>
        <InfoSection>
          <SubHeader>{t('details')}</SubHeader>
          <InfoTableComponent
            tag="time"
            description={t('time')}
            content={shiftInterval.toFormat('t')}
          />
          <InfoTableComponent
            tag="total"
            description={t('total')}
            content={durationToString(
              shiftInterval
                .toDuration(['hour', 'hours', 'minute', 'minutes', 'second', 'seconds'])
                .toObject(),
              t
            )}
          />
          <InfoTableComponent tag="username" description={t('username')} content={username} />
          <InfoTableComponent tag="workplace" description={t('workplace')} content={workplace} />
          {workplace.trim() === 'Urlaub' && (
            <InfoTableComponent tag="status" description={t('status')} content={status} />
          )}
          <InfoTableComponent tag="subproject" description={t('subproject')} content={subproject} />
          <InfoTableComponent
            tag="meeting_point"
            description={t('location')}
            content={meeting_point}
          />
          <InfoTableComponent tag="address" description={t('address')} content={address} />
          <InfoTableComponent tag="kolonne" description={t('kolonne')} content={kolonne} />
          <InfoTableComponent
            tag="machine"
            description={t('machine_type')}
            content={machine.name}
          />
          <InfoTableComponent
            tag="accommodation"
            description={t('accommodation')}
            content={accommodation}
          />
          <InfoTableComponent tag="name" description={t('label')} content={comment} />
        </InfoSection>
        <InfoSection>
          <SubHeader>{t('contacts')}</SubHeader>
          <InfoTableComponent description={t('name')} content={contact} />
          <InfoTableComponent description={t('phone')} content={contact_phone} />
        </InfoSection>
        <InfoSectionDesktopOnly className="desktop-only">
          <SubHeader>{t('related_personnel')}</SubHeader>
          {(!personnel || personnel.length === 0) && <p>{t('no_personnel')}</p>}
          {personnel &&
            personnel.length > 0 &&
            personnel.map((personForList, index) => {
              const personStartDate = Luxon.DateTime.fromISO(personForList.start);
              return (
                <InfoTableComponent
                  key={index}
                  description={personForList.username}
                  description_sub={personForList.phone}
                  content={personStartDate.toFormat('dd.MM. HH:mm')}
                />
              );
            })}
        </InfoSectionDesktopOnly>
        <InfoSectionDesktopOnly className="desktop-only">
          <SubHeader>{t('related_machines')}</SubHeader>
          {(!machines || machines.length === 0) && <p>{t('no_machines')}</p>}
          {machines &&
            machines.length > 0 &&
            machines.map((machineForList, index) => {
              const machineStartDate = Luxon.DateTime.fromISO(machineForList.start);
              const machineEndDate = Luxon.DateTime.fromISO(machineForList.end);
              const interval = Luxon.Interval.fromDateTimes(machineStartDate, machineEndDate);
              const startString = interval.start.toFormat('dd.MM. HH:mm');
              const endString = ' - ' + interval.end.toFormat('dd.MM. HH:mm');
              return (
                <InfoTableComponent
                  key={index}
                  description={machineForList.title}
                  content={[startString, endString]}
                />
              );
            })}
        </InfoSectionDesktopOnly>
      </ShiftDetailsContainer>
    </ThemeProvider>
  );
};
